import Headers from "./Header";
import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import AOS from "aos";

function Body() {
  useEffect(() => {
    AOS.init();
  }, []);

  const scrollToRegister = () => {
    const registerSection = document.getElementById("register");
    if (registerSection) {
      registerSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the form is submitted

  // On page load, check if the user has already submitted the form
  useEffect(() => {
    // Clear localStorage on page load to allow re-submission after refresh
    localStorage.removeItem("isSubmitted");

    // Check if the form was already submitted (saved in localStorage)
    const isAlreadySubmitted = localStorage.getItem("isSubmitted");
    if (isAlreadySubmitted === "true") {
      setIsSubmitted(true); // Mark as already submitted
    } else {
      setIsSubmitted(false); // Allow submission if not submitted yet
    }
  }, []);

  // Email validation function
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handleRegister = async () => {
    // Validate email format before submitting
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    // Check if the user has already submitted the form to prevent duplicate submissions
    if (isSubmitted) {
      return;
    }

    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    setIsSubmitted(true); // Mark as submitted

    // Store the submission status in localStorage to prevent duplicate submissions
    localStorage.setItem("isSubmitted", "true");

    try {
      // API call using fetch
      const response = await fetch('https://dev.petochat.com/api/petochat/v1/auth/website', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),  // Send email in request body
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('You have successfully pre-registered! Check your email for further instructions.');
      } else {
        setErrorMessage(data.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Section 1 */}
      <div id="home" className="container-fluid top_banner_bg">
        <Headers />
        <div className="container mt-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <h2 className="welcome_text_left laila-bold">
                <span className="ms-4">Welcome to PetoChat</span>
              </h2>
              <h1 className="where_Pets">
                Where Pets and Their People Connect
              </h1>
              <p className="where_pets_para">
                Join the ultimate pet community for socializing, care services,
                veterinary help, and pet products—all in one place.
              </p>
              <div className="mt-3 sm-flex">
                <motion.button
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  type="button"
                  className="contactus_top_btn"
                  onClick={scrollToRegister}
                >
                  Pre-Register
                </motion.button>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                src="./images/right-img.png"
                className="img-fluid"
                alt="right side banner"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div id="problem" className="container mt-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
            <p className="section_headings">
              <span className="ms-4  me-2">Petochat services</span>
            </p>
            <p className="section_subheading">What we are solving</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3 mt-5">
            <div className="what_solving_box1">
              <div
                className="whatsolvingimg"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div>
                  <img
                    src="./images/whatsolving2.png"
                    className="img-fluid "
                    alt="what solving 1"
                  />
                </div>
                <div className="whatsolving_main">
                  <p className="what_solving_para mb-3">
                    Uniting Pet <br></br>
                    Owners
                  </p>
                  <p className="what_solving_subpara">
                    Uniting Pet Owners and Providing Comprehensive Pet Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-5">
            <div className="what_solving_box1">
              <div
                className="whatsolvingimg"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div>
                  <img
                    src="./images/whatsolving3.png"
                    className="img-fluid "
                    alt="what solving 1"
                  />
                </div>
                <div className="whatsolving_main">
                  <p className="what_solving_para mb-3">
                    Fragmented<br></br> Services
                  </p>
                  <p className="what_solving_subpara">
                    Finding reliable pet care, veterinary services, and pet
                    products can be cumbersome
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-5">
            <div className="what_solving_box1">
              <div
                className="whatsolvingimg"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div>
                  <img
                    src="./images/whatsolving1.png"
                    className="img-fluid "
                    alt="what solving 1"
                  />
                </div>
                <div className="whatsolving_main">
                  <p className="what_solving_para mb-3">
                    Disconnected <br></br> Communities
                  </p>
                  <p className="what_solving_subpara">
                    Many pet owners feel isolated and lack a supportive communit
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-5">
            <div className="what_solving_box1">
              <div
                className="whatsolvingimg"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div>
                  <img
                    src="./images/whatsolving4.png"
                    className="img-fluid "
                    alt="what solving 1"
                  />
                </div>
                <div className="whatsolving_main">
                  <p className="what_solving_para mb-3">
                    Adoption <br></br> Challenges
                  </p>
                  <p className="what_solving_subpara">
                    Matching pets with suitable owners and ensuring a smooth
                    adoption process is often difficult
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section 3 */}
      <section className="position-relative" data-aos="zoom-in-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000">
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
              <img
                src="./images/section-3.png"
                alt="section banner"
                className="img-fluid mr-img"
              />
            </div>
          </div>
        </div>

      </section>
      {/* Section 4 */}
      <div id="solution" className="container mt-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-5 sm-mb">
            <p className="section_headings">
              <span className="ms-4 me-2">Petochat services</span>
            </p>
            <p className="section_subheading">How we are solving</p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-12 col-md-6 col-lg-6"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <p className="section_headings font-18">
              <span className="ms-4  me-2">How we Solves </span>
            </p>
            <p className="section_subheading font-32 mt-2">
              Connected Community
            </p>
            <div className="ms-3">
              <ul>
                <li className="connected_list">
                  Pet Profiles -
                  <span className="poppins-regular">
                    Create unique profiles for your pets and share their
                    stories.
                  </span>
                </li>
                <li className="connected_list">
                  Social Engagement -
                  <span className="poppins-regular">
                    Post updates, like, comment, and share content within a
                    vibrant community
                  </span>
                </li>
                <li className="connected_list">
                  Groups and Events -{" "}
                  <span className="poppins-regular">
                    Join or create groups and participate in pet-related events
                    and meetups. Integrated Services
                  </span>
                </li>
              </ul>
            </div>

            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              type="button"
              className="read_more_btn me-3 mt-4"
              onClick={scrollToRegister}
            >
              Pre-Register Today
            </motion.button>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center sm-mt-2">
            <img
              src="./images/howwe1.png"
              className="img-fluid"
              alt="how we solving 1"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-start">
            <img
              src="./images/howwe2.png"
              className="img-fluid"
              alt="how we solving 2"
            />
          </div>
          <div
            className="col-sm-12 col-md-6 col-lg-6"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <p className="section_headings font-18">
              <span className="ms-4  me-2">Pet Care</span>
            </p>
            <p className="section_subheading font-32 mt-2">Pet Marketplace</p>
            <div className="ms-3">
              <ul>
                <li className="connected_list">
                  Pet Care Services -
                  <span className="poppins-regular">
                    Find and book grooming, training, and daycare services with
                    ease
                  </span>
                </li>
                <li className="connected_list">
                  Veterinary Care -
                  <span className="poppins-regular">
                    Access 24/7 veterinary advice, book appointments, and manage
                    health records digitally.
                  </span>
                </li>
                <li className="connected_list">
                  Pet Products Marketplace -
                  <span className="poppins-regular">
                    Explore a wide range of products with community reviews and
                    exclusive deals
                  </span>
                </li>
              </ul>
            </div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              type="button"
              className="read_more_btn me-3 mt-4"
              onClick={scrollToRegister}
            >
              Pre-Register Today
            </motion.button>
          </div>
        </div>
        <div className="row mt-5  sm-mt-1">
          <div
            className="col-sm-12 col-md-6 col-lg-6"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <p className="section_headings font-18">
              <span className="ms-4  me-2">Adoption Ease</span>
            </p>
            <p className="section_subheading font-32 mt-2">
              Streamlined Adoption
            </p>
            <div className="ms-3">
              <ul>
                <li className="connected_list">
                  Adoption Listings -
                  <span className="poppins-regular">
                    Browse profiles of pets available for adoption
                  </span>
                </li>
                <li className="connected_list">
                  Trusted Organizations -
                  <span className="poppins-regular">
                    Connect with reputable shelters and rescue groups
                  </span>
                </li>
                <li className="connected_list">
                  Adoption Assistance -
                  <span className="poppins-regular">
                    Receive guidance and support throughout the adoption
                    process. Solving
                  </span>
                </li>
              </ul>
            </div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              type="button"
              className="read_more_btn me-3 mt-4"
              onClick={scrollToRegister}
            >
              Pre-Register Today
            </motion.button>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center sm-mt-2">
            <img
              src="./images/howwe3.png"
              className="img-fluid"
              alt="how we solving 3"
            />
          </div>
        </div>

      </div>
      {/* Section 5 */}
      <div id="register" className="container-fluid pre-register-bg mt-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 align-items-center justify-content-center d-flex flex-column">
            <p className="pre_register_heading">Pre-Register</p>
            <p className="pre_register_subheading">
              Pre-register for the app now to get early access
            </p>
            <div className="input-group mb-3 enter_email_input_main mt-3">
              {/* First Input for Email */}
              <input
                type="text"
                className="form-control enter_email_input me-3"
                placeholder="Enter email here"
                value={email}
                onChange={handleEmailChange}
                disabled={isSubmitted} // Disable input after submission
              />
              {/* Register Button */}
              <span
                className="input-group-text register_btn"
                id="basic-addon2"
                onClick={handleRegister}
                style={{ cursor: isSubmitted ? 'not-allowed' : 'pointer' }}
              >
                {isSubmitted ? 'Registered' : 'Register'}
              </span>
            </div>
            {isLoading && <p>Loading...</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      </div>

    </>
  );
}

export default Body;
