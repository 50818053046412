function Footer() {
  return (
    <>
      <div className="container-fluid footer_bg mt-8rem">
        <div className="container">
          <div className="row pb-5rem">
            <div className="col-sm-12 col-md-5 col-lg-7">
              <img
                src="./images/logo.png"
                alt="footer logo"
                className="img-fluid"
              />
              <p className="footer_para">
                Because every pet has a story.
              </p>
              <div className="mt-3 d-flex align-items-center">
                <p className="follow_us me-3">Follow us on</p>
                <a href="#home" className="footer_social_icons">
                <i className="fa-brands fa-facebook-f"></i>
                </a>
                {/* <a href="#home" className="footer_social_icons">
                <i className="fa-brands fa-x-twitter"></i>
                </a> */}
                <a href="https://www.instagram.com/petochatofficial/profilecard/?igsh=MWprZ2RvNzZ0dXo1Ng==" className="footer_social_icons">
                <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2">
              <div className="footer-col">
                <h3 className="footer-head">Our Pages</h3>
                <div className="d-flex flex-column">
                  <a href="#home" className="footer_links">
                    Home
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                    Latest Post
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                    Our Team
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                    Our Vision
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                    FAQ
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-3">
              <div className="footer-col">
                {/* <h3 className="footer-head">Useful links</h3> */}
                <h3 className="footer-head">Coming soon</h3>
                <div className="d-flex align-items-center">
                <div className="d-flex flex-column me-5">
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Pet Grooming
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Pet Care
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Pet Vets
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Pet Market
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Pet Shopee
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Puppy food
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Adult Food
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Adult gravy
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Cat food
                  </a>
                  <a href="#home" className="footer_links" onClick={(e) => e.preventDefault()}>
                  Cat Market
                  </a>
                </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-6 col-lg-3" id='subscribeNewsletter'>
                <h3 className="footer-head">Subscribe Newsletter</h3>
                <input
                  type="text"
                  className="form-control enter_email_input enter_email_input_bg mb-3"
                  placeholder="Enter name"
                />
                   <input
                  type="text"
                  className="form-control enter_email_input enter_email_input_bg mb-3"
                  placeholder="Enter email"
                />
                <button type="button" className="subscribe_btn">Subscribe</button>
            </div> */}
          </div>
        </div>
       
      </div>
      <div className="container-fluid footer-black-bg ">
        <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
          <p className="copyright_text">Copyright © 2025 Koderscape Technologies Pvt Ltd, All rights reserved.</p>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
